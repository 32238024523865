<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card>
          <b-card-body>
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label class="mr-1">Show</label>
                <Select
                  @on-change="paginateChange"
                  v-model="search.paginate"
                  :clearable="false"
                >
                  <Option :value="10"> 10 </Option>
                  <Option :value="30"> 30 </Option>
                  <Option :value="50"> 50 </Option>
                  <Option :value="80"> 80 </Option>
                  <Option :value="100"> 100 </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="5">
                <Select
                  v-model="search.status"
                  @on-change="SearchData"
                  :clearable="true"
                  placeholder="Select Status"
                >
                  <Option :value="1"> Active </Option>
                  <Option :value="2"> Inactive </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="5">
                <Input
                  v-model="search.search_data"
                  class="d-inline-block mr-1"
                  placeholder="Search name..."
                  type="text"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-text>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-center">Sl</th>
                    <th>Name</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Created At</th>
                    <th
                      class="text-center"
                      v-if="permission.edit || permission.delete"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(course, index) in courses.data"
                    :key="index"
                    v-if="courses.data.length"
                  >
                    <td class="align-middle text-center">
                      {{ index + courses.from }}
                    </td>
                    <td><b></b>{{ course.name }}</td>
                    <td class="align-middle text-center">
                      <span
                        class="badge"
                        :class="
                          course.status == 1 ? 'badge-success' : 'badge-danger'
                        "
                      >
                        {{ course.status == 1 ? "Active" : "Inactive" }}
                      </span>
                    </td>
                    <td class="text-center">
                      {{ course.created_at | date_time_format }}
                    </td>
                    <td
                      class="align-middle text-center"
                      v-if="permission.edit || permission.delete"
                    >
                      <button
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        @click="Edit(course.id)"
                      >
                        <feather-icon icon="EditIcon" size="16" />
                      </button>

                      <button
                        v-if="permission.delete"
                        class="btn btn-danger btn-sm ml-1"
                        @click="Delete(course.id)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-card-body>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-muted"
                  >Showing {{ courses.from }} to {{ courses.to }} of
                  {{ courses.total }} entries</span
                >
                <pagination
                  :data="courses"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </div>
            </b-card-body>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != null">
        <b-card :title="(!form.id ? 'Create' : 'Update') + ' course Info'">
          <b-form @submit.prevent="!form.id ? Create() : Update(form.id)">
            <b-row class="justify-content-center">
              <b-col cols="12">
                <label> Name </label>
                <Input
                  v-model="form.name"
                  placeholder="Write Course Name"
                  style="width: 100%"
                  type="text"
                />
                <has-error :form="form" field="name"></has-error>
              </b-col>

              <b-col cols="12">
                <div
                  class="d-flex justify-content-between align-items-center mt-2"
                >
                  <b-form-checkbox
                    name="status"
                    style="margin-top: 5px"
                    v-model="form.status"
                    switch
                    inline
                  />
                  <div>
                    <b-button variant="danger" @click="Clear">
                      Clear
                    </b-button>
                    <b-button
                      variant="primary"
                      class="ml-1"
                      type="submit"
                      :disabled="form.busy"
                    >
                      {{ form.id ? "Update" : "Create" }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        status: true,
      }),
      search: {
        search_data: "",
        status: "",
        paginate: 10,
        page: 1,
      },
      courses: {},
    };
  },
  components: {
    BCardBody,
    BCard,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
    HasError,
    AlertError,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/course${queryParams}`)
        .then((res) => {
          this.courses = res.data.courses;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.status = true;
    },

    Create() {
      this.form
        .post("/app/course")
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Update(id) {
      this.form
        .put("/app/course/" + id)
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
         this.e(e.response.data.message);
        });
    },

    Edit(id) {
      axios
        .get("/app/course/" + id + "/edit")
        .then((res) => {
          this.form.id = res.data.course.id;
          this.form.name = res.data.course.name;
          this.form.status = res.data.course.status == 1 ? true : false;
        })
        .catch((e) => {
         this.e(e.response.data.message);
        });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/app/course/" + id)
            .then((res) => {
              this.s(res.data.message);
              if (this.courses.data.length == 1) {
                this.search.page = 1;
              }
              this.getResults();
            })
            .catch((e) => {
               this.e(e.response.data.message);
            });
        }
      });
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.course;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
